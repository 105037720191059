import React from 'react';
import BannerSection from './bannerSection';
import FormSection from './formSection';
import HowItsWorkExpert from './howItsWork';
import ApplicationProcessExpert from './applicationProcess';
import CommunityExpert from './communityExperts';
import FaqSection from './faqSection';
import { expertsData } from '../../../services/api';
import TestimonialComponent from '../../Layouts/crousel/testimonialCrousel';
import { testimonialContent } from '../../../data/content';
import Loader from '../../Tools/Loader';
const ExpertPage = () => {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        getExpertData();
    }, []);
    const getExpertData = () => {
        setIsLoading(false);
        expertsData().then((response) => {
            if (response.data.status) {
                setData(response.data.data);
                setIsLoading(true);
            }
        }).catch((e) => {
            console.log("error", e);
        })
    }
    return (<>
        {!isLoading ? <Loader /> : ""}
        <BannerSection data={data} />
        <FormSection data={data} />
        <HowItsWorkExpert data={data} />
        <ApplicationProcessExpert data={data} />
        <CommunityExpert data={data} />
        <TestimonialComponent content={testimonialContent} heading={"What Experts are saying about Inquirely.com"} />
        <FaqSection />
    </>)
}

export default ExpertPage;