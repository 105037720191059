import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const VerticalCarousel = ({ items, tab }) => {
  console.log('-------------iitem--------',items);
  //const [currentIndex, setCurrentIndex] = useState(0);

  // const handleScroll = (event) => {
  //   if (event.deltaY > 0) {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  //   } else if (event.deltaY < 0) {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === 0 ? items.length - 1 : prevIndex - 1
  //     );
  //   }
  // };
  // onWheel={handleScroll}

//   return (
//     <>
//     {items.map((item, index) => (
//     <section className="vertical_slider" id={`work${index}`}>
//             <div className="row h-100">
//                 <div className="col-lg-6">
//                   <div className='divh3'>
//                   <h3><span>How It works</span></h3>

//                   <ul class='scrollfix1'>
//    <li class="c1"><a href="#work0"><span></span></a></li>
//    <li class="c2"><a href="#work1"><span></span></a></li>
//    <li class="c3"><a href="#work2"><span></span></a></li>
//    <li class="c4"><a href="#work3"><span></span></a></li>
//    <li class="c5"><a href="#"><span></span></a></li>
//    <li class="c6"><a href="#"><span></span></a></li>
//    <li class="c7"><a href="#"><span></span></a></li>
// </ul>
                
//                   </div>
//                   <div className='work_left'>
//                     <div dangerouslySetInnerHTML={{ __html: item && item ? item : "" }}/>
//                     {/* <h2>Lorem ipsum dolor sit</h2>
//                     <p>Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris. Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris.</p>
//                     <h2>Lorem ipsum dolor sit</h2>
//                     <p>Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris. Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris.</p> */}
//                 </div>
//                 </div>
//                 <div className="col-lg-6 work_side">
//                     <div className="work_img">
//                         <img src={`assets/images/dash_img/screen${index+1}.png`} alt="" />
//                     </div>
//                 </div>
//             </div>
  
//     </section>
//     ))}
//     </>
//   );
console.log("Tab", tab);
  return ( <section className="vertical_slider">
    <div class="container">
    <Tabs>
  <TabList>
    {tab && tab.map((item)=>{
      return <Tab>{item}</Tab>
    })}
  </TabList>
  {items.map((item, index) => (
  <TabPanel>
        
             <div className="row h-100">
                <div className="col-lg-6">
                   <div className='divh3'>
                   <h3  className='wow slideInLeft' data-wow-delay='.1s'><span>How It works</span></h3>
                   <ul class='scrollfix1'>
    <li class="c1"><a href="#work0"><span></span></a></li>
    <li class="c2"><a href="#work1"><span></span></a></li>
    <li class="c3"><a href="#work2"><span></span></a></li>
    <li class="c4"><a href="#work3"><span></span></a></li>
    <li class="c5"><a href="#"><span></span></a></li>
    <li class="c6"><a href="#"><span></span></a></li>
    <li class="c7"><a href="#"><span></span></a></li>
 </ul>
                
                </div>
                   <div className='work_left'>
                     <div dangerouslySetInnerHTML={{ __html: item && item.howItsTitle ? item.howItsTitle : "" }}/>
                     <div dangerouslySetInnerHTML={{ __html: item && item.howitsContent ? item.howitsContent : "" }} />
                     {/* <h2>Lorem ipsum dolor sit</h2>
                     <p>Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris. Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris.</p>
                     <h2>Lorem ipsum dolor sit</h2>
                     <p>Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris. Lorem ipsum dolor sit amet consectetur. Libero nisi at ut erat volutpat curabitur eu vestibulum tortor. Lobortis justo id condimentum non risus ullamcorper molestie ante condimentum. Adipiscing ante sollicitudin elit suspendisse turpis habitasse ullamcorper mauris.</p> */}
                 </div>
                 </div>
                 <div className="col-lg-6 work_side">
                    <div className="work_img">
                      <img className='wow slideInRight' data-wow-delay='.1s'  src={`assets/images/dash_img/screen${index+1}.png`} alt="" />
                    </div>
                 </div>
              </div>
  </TabPanel>))}
</Tabs></div></section>);
};

export default VerticalCarousel;