import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './Reducer/authSlice';
import detailReducer from './Reducer/detailSlice';
import categorySlice from './Reducer/categorySlice';
import loaderSlice from './Reducer/loaderSlice';
const rootReducer = combineReducers({
    auth:authReducer,
    detail:detailReducer,
    loader:loaderSlice,
    singleCategory:categorySlice
});

export default rootReducer;