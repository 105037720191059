import React, { useEffect } from 'react';
//import useScript from 'react-script-hook';
import $ from 'jquery';

const SalesforceChatbot = ({name}) => {
   //const [loading, error, success] = useScript({ src: `https://digittrix-staging.com/staging/inquirely/public/${name}.js` });

   let time='';

   // time = setInterval(() => {
   //    validatefun();
   // }, 2000);

   // const validatefun=()=>{
   //    let chatbot = $('.modalContainer').html();
   //    if(chatbot && chatbot!=undefined) {
   //       $(".modalContainer").appendTo(".txn");
   //       setTimeout(() => {
   //          $('.load-chat').addClass('d-none');
   //       }, 3000);
   //       clearTimeout(time);
   //    }   
   // }

  return (
     <div class="txn" id="sliding">
{/* <div className="load-chat"><img src={'assets/images/dash_img/load.gif'}/></div> */}
      <div className="load-chat" style={{ 
      backgroundImage: `url('assets/images/wf.png')`  
    }}></div>
         {name == "first" || name == "third" || name == "fourth" ? <p onload="gslbBaseURL()"> </p>: ""}
         {name == "second" ? <p onload="gslbBaseURL1()"></p> : ""}
    </div>
  );
};

export default SalesforceChatbot;