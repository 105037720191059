import { checkVideoExixt } from "../../../services/helper";
const BannerSectionExpert = ({data}) => {
    return (<> <section id="home_banner" style={{ backgroundImage: `url("https://adminaccess.inquirely.com/inquirely//public/uploads/${data ? data.banner : "home-banner.png"}")` }}>
        {checkVideoExixt(data && data.banner ? data.banner : "") ? <video loop autoplay="" muted>
            <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data && data.banner}`} type="video/mp4" />
        </video> : ""}
        <div className="container">
            <div className="row">
            </div>
        </div>
    </section> </>)
}

export default BannerSectionExpert;