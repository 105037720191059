import BannerSection from "./bannerSection";
import StateSection from "./statsSection";
import LeadershipSection from "./leadershipSection";
const AboutUs = () => {
  return (<>
    <BannerSection />
    <StateSection />   
    <LeadershipSection />
    </>)
}

export default AboutUs;