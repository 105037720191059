import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import SalesforceChatbot from '../../chatbot/chatbot';
import { checkVideoExixt } from '../../../services/helper';

const BannerSection = ({ data, value, spotlight, categoryTestimonial }) => {
    const testimonials = categoryTestimonial && categoryTestimonial.map((item, i) => {
        if(i == 0) {
            return {
                ...item,
                class: "active"
            }
        }
        else {
            return {
                ...item,
                class: ""
            }
        }
    })
    console.log("category testimonial", testimonials)
    return (<>  <section id="legal_sec">
        {checkVideoExixt(data && data.banner_image ? data.banner_image : "") ? <video loop autoplay="" muted>
            <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data && data.banner_image}`} type="video/mp4" />
        </video> : ""}
        <div class="container">
            <div className="test">
                <SalesforceChatbot name={value} />
            </div>
            <div class="row">
                {/* <div class="col-lg-6">
                    <section class="msger">
                        <header class="msger-header">
                            <div class="msger-header-title">

                                <div class="msger_img">
                                    <img src="images/test1.png" alt="" />
                                </div>

                                <div class="msger_heading">
                                    <h5>Eleanor Pena</h5>
                                    <p>Lorem ipsum dolor sit amet</p>
                                </div>

                            </div>
                            <div class="msger-header-options">
                                <span> <a class="btn" href="#">More</a> </span>
                            </div>
                        </header>

                        <main class="msger-chat">
                            <div class="msg left-msg">
                                <div class="msg-img" style={{ backgroundImage: `url('./images/test1.png')` }}></div>

                                <div class="msg-bubble">

                                    <div class="msg-text">
                                        Lorem ipsum dolor sit amet consectetur.
                                    </div>
                                </div>
                            </div>

                            <div class="msg right-msg">
                                <div class="msg-img" style={{ backgroundImage: `url('./images/test2.png')` }}></div>

                                <div class="msg-bubble">


                                    <div class="msg-text">
                                        Lorem ipsum dolor sit amet consectetur. Ligula consequat quam maecenas quis tincidunt habitant. Arcu porttitor odio turpis.
                                    </div>
                                </div>
                            </div>
                        </main>

                        <form class="msger-inputarea">
                            <textarea class="msger-input" placeholder="Lorem ipsum dolor sit amet consectetur."></textarea>
                            <button type="submit" class="msger-send-btn">Send</button>
                        </form>
                    </section>
                </div> */}

                {/* <div class="col-lg-6">
                    <h1>Legal</h1>
                    <p>Providing legal advice and assistance across various practice areas, such as family law, immigration law, criminal law, employment law, real estate law, and more.</p>
                </div> */}
            </div>
        </div>
    </section>


        <section className='banner_down'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <h3>JustAnswer in Spotlight</h3>
                        <div className='set'>
                            <div className='row'>
                                {spotlight && spotlight.length > 0 && spotlight.map((item, key) => {
                                    return (<div className='col-sm-4' key={key}>
                                        <div className='img'>
                                            <Link to={`/spotlight/${item.id}`}><img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${item.icon}`} alt="" /></Link>
                                        </div>
                                    </div>)
                                })}
                                {/* <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='img'>
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/1692043251logo.png`} alt="" />
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                    <div className='col-sm-6'>
                        <h3>What Customers are Saying</h3>
                        <OwlCarousel
                            className="owl-theme"
                            items={1}
                            loop margin={10} nav
                        >
                           {testimonials && testimonials.length > 0 ? testimonials.map((item, index) => {
                              return (<div className={`set ${item.class}`} key={index}>
                              <h5 className='mb-3'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="star" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="star" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="star" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="star" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="star" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                              </h5>
                              <p>{item.description}</p>
                              <h6 className='text-end'>Michael</h6>
                              <p className='text-end'>Wichita, KS</p>
                          </div>);
                           }) : ""}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}
export default BannerSection;