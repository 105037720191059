import React from "react";
import BannerSection from "./bannerSection";
import Content from "./content";
import { getByIdCustomPages } from "../../../services/api";
const CustomPages = () => {
    const [data,setData] = React.useState([]);
    React.useEffect(() => {
        let urlParams = window.location.href.split('/');
        let customPagesName = urlParams[urlParams.length - 1].replace(/_/g, ' ');
        getByIdCustomPagesFun(customPagesName)
    }, [window.location.href]);
    const getByIdCustomPagesFun = async (name) => {
        const body = {
            pageName: name
        }
        try {
          const response = await getByIdCustomPages(body);
          if(response.data.status) {
            setData(response.data.data);
          }
        } catch (e) {
            console.log("error", e);
        }
    }
    console.log("data", data);
    return (<>
        <BannerSection  title={"About Us"} image={data && data.banner_image ? data.banner_image : '1690378457.jpg'} />
        <Content content={data && data.content} />
        </>)
}

export default CustomPages;