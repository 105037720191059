import React from "react";
import { Leader } from "../../../data/content";
import BoostrapModal from "../../Layouts/BoostrapModal";
const LeadershipSection = () => {
    const [show, setShow] = React.useState(false);
    const  [data, setData] = React.useState({});
    return (<section id="leadership" onClick={() => setShow(!show)}>
        <div class="container">
            <div class="row">
                <h1>Meet our leadership team</h1>
                {Leader.map((e, i) => {
                   return <div class="col-lg-4 col-sm-6" onClick={() => setData(e)}>
                        <div class="leader_box">
                            <img src={e.image} alt="" />
                            <h3>{e.name}</h3>
                            <p>{e.specialist}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <BoostrapModal show={show} onHide={()=>setShow(false)}  data={data} />
    </section>)
}

export default LeadershipSection;