import { checkVideoExixt } from "../../../services/helper";
const CommunityExpert = ({data}) => {
  return (
    <section id="community_sec">
        <div className="container">
            <div className="row">
                <div className="col-lg-7"
                    dangerouslySetInnerHTML={{ __html: data.community_content }}
                />
                <div className="col-lg-5">
                    <div className="community_img">
                        {checkVideoExixt(data && data.community_image ? data.community_image : "") ? <video loop autoplay="" muted>
                            <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data.community_image}`} type="video/mp4" />
                        </video> : <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data.community_image}`} alt="" />}
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CommunityExpert;