import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Mousewheel, Pagination } from 'swiper/modules';

const Test = () => {
  const [height, setHeight] = useState(30);
  const handleClick = () => {
    setHeight(height + 30);
  };
    return (
      <div>
           <div
        style={{ height: height, transition: 'all 2s' }}
        onClick={handleClick}
      >
        Text
      </div>
      <div style={{backgroundColor: "red", height: height, width: "20px",  transition: 'all 2s'}}></div>
    </div>
    )
}

export default Test;