const StateSection = () => {
    return (
        <section id="counter">
            <div class="container">
                <div class="stats">
                    <div class="stats__stat"><span class="stats__value">12K</span><span class="stats__label">Experts</span></div>
                    <div class="stats__stat"><span class="stats__value">700</span><span class="stats__label">Categories</span></div>
                    <div class="stats__stat"><span class="stats__value">196</span><span class="stats__label">Countries</span></div>
                    <div class="stats__stat"><span class="stats__value">38</span><span class="stats__label">Currencies</span></div>
                    <div class="stats__stat"><span class="stats__value">4</span><span class="stats__label">Languages</span></div>
                </div>
            </div>
        </section>
    )
}

export default StateSection;