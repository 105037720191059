import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { spacingExixtReplaceUnderscore } from "../../../services/helper";
import { allCategory } from '../../../services/api';
import ListItem from '../../Tools/ListItem';
const CategoryCrousel = () => {
    const [categoryData, setCategoryData] = React.useState([]);
    const state = {
        responsive: {
            0: {
                items: 1
            },

            600: {
                items: 3
            },
            1000: {
                items: 6
            }
        }
    }
    React.useEffect(() => {
        getAllCategory()
    }, [setCategoryData]);

    const getAllCategory = () => {
        try {
            allCategory().then((response) => {
                if (response.data.status) {
                    setCategoryData(response.data.data)
                }
            }).catch((error) => {
                console.log("error", error);
            })
        }
        catch (e) {
            console.log("error", e);
        }
    }
    let value = true;
    return (<div id="more_sec">
        <div className="container-fluid">
            <div className="row" style={{ textAlign: "center" }}>
                <ul>
                    {categoryData.length > 0 ? categoryData.map((item, index) => {
                        console.log("index", index);
                        if (index == 8) {
                            value = false
                        }
                        else {
                            return value ? <li key={index}><a href={`/inquirely/${spacingExixtReplaceUnderscore(item.name)}`}><img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${item.icon}`} />{item.name}</a></li> : null;
                        }
                    }) : null}
                    {/* <li><a href={'#'}><img src={`assets/images/More.png`} />{"More"}</a></li> */}
                </ul>
            </div>
        </div>
    </div>)
}
export default CategoryCrousel;