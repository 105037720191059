import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../Tools/Button';
import Payment from '../../Tools/Payment';
import { profile_details } from '../../../services/api';
const Profile = () => {
    const [show, setShow] = useState(false);
    const [wallet, setWallet] = useState(0);
    const handleClose = () => setShow(false);


    useEffect(()=>{
        get_profile();
    },[]);

    const calculateAmount=async(data)=>{
        console.log('data',data);
        let i=0;
        for await (const num of data) {
            console.log(num);
          i+=Number(num.amount);
        }
        setWallet(i);
    }
  
      const get_profile = async()=>{
        let ds={uid:18};
        let add= await profile_details(ds);
        if(add.status==200)
        {
          calculateAmount(add.data.result);
        }
      }

    return (<><section id="user_profile">
    <div class="container">

        <div class="pro_div_pro">

            <ul class="nav nav-pills" id="pills-tab" role="tablist">

                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Profile</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Wallet</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-experts-tab" data-bs-toggle="pill" data-bs-target="#pills-experts" type="button" role="tab" aria-controls="pills-experts" aria-selected="false">Experts</button>
                </li>

            </ul>

            <div class="tab-content" id="pills-tabContent">

                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                    <div class="profile_form">

                        <div class="row">

                            <div class="col-lg-3">
                                <label class="form-label"><i class="bi bi-person-bounding-box"></i>Profile</label>
                                <div class="imagefile">
                                    <span><i class="fas fa-camera"></i>
                                        <input type='file' onchange="readURL(this);" />
                                    </span>

                                    <img class="w-100" id="blah" src="assets/images/m4.png" alt="your image" />
                                </div>
                            </div>

                            <div class="col-lg-9">
                                <div class="row">

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Name</label>
                                            <input type="text" class="form-control" placeholder="Enter name"/>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Email</label>
                                            <input type="email" class="form-control" placeholder="Enter email"/>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label class="form-label">Phone</label>
                                            <input type="text" class="form-control" placeholder="Enter phone number"/>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                            <textarea class="form-control" placeholder="Enter description"> </textarea>
                                        </div>
                                    </div>

                                </div>



                            </div>

                            <div class="col-lg-12">
                                <div class="profile_expert">
                                    <h2>Experts History</h2>

                                    <div class="profile_designation">
                                        <div class="designation_img">
                                            <img src="assets/images/test3.png" alt=""/>

                                            <div class="designation_heading">
                                                <h5>Eleanor Pena <span> <a href="#" class="btn">Chat again</a> </span></h5>

                                                <p>CEO</p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                    <div class="transation_wallet">

                        <h2>Wallet</h2>

                        <div class="profile_expert mt-0 mb-3">

                            <div class="profile_designation wallet_tabb">
                                <div class="designation_img">
                                    <img src="assets/images/test3.png" alt=""/>

                                    <div class="designation_heading">
                                        <h5>Darlene Robertson <p> Inquirely Wallet <br/><span>$ {wallet}</span> </p>
                                        </h5>

                                        <p>CEO</p>
                                    </div>
                                </div>


                            </div>
                            <div class="recharge_sec">
                                <h6>Recharge your finds to get Started <span> <a class="btn" onClick={()=>setShow(true)} > <img src="assets/images/down_btn.png" alt=""/> Add Fund</a> </span> </h6>
                            </div>

                            <div class="toggle-btn-grp cssonly">
                                <div><input type="radio" name="group4" /><label onclick="" class="toggle-btn">$50</label></div>
                                <div><input type="radio" name="group4" /><label onclick="" class="toggle-btn">$100</label></div>
                                <div><input type="radio" name="group4" /><label onclick="" class="toggle-btn">$200</label></div>
                                <div><input type="radio" name="group4" /><label onclick="" class="toggle-btn">$500</label></div>
                            </div>


                        </div>


                        <h3>Total Amount: $2000</h3>

                        <div class="table-responsive">

                            <table class="table wine_table">
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Price</th>
                                        <th>Payment Status</th>
                                        <th>Order Status</th>
                                        <th>Product details</th>
                                    </tr>
                                </thead>

                                {/* <tbody>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                </tbody> */}

                            </table>

                        </div>


                    </div>
                </div>

                <div class="tab-pane fade" id="pills-experts" role="tabpanel" aria-labelledby="pills-experts-tab">

                    <div class="transation_wallet">

                        <h2>Experts</h2>

                        <div class="table-responsive">

                            <table class="table wine_table">
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Price</th>
                                        <th>Payment Status</th>
                                        <th>Order Status</th>
                                        <th>Product details</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                    <tr>
                                        <td>#08934</td>
                                        <td>01-05-2023</td>
                                        <td>12:00 PM</td>
                                        <td>$200</td>
                                        <td>Completed</td>
                                        <td>Delivered</td>
                                        <td> <a href="#"> View Detail</a></td>

                                    </tr>

                                </tbody>

                            </table>

                        </div>


                    </div>
                </div>

            </div>

        </div>

    </div>
</section>
<Modal show={show} className='add_funds modal-dialog-centered' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Funds</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Payment remove={handleClose}/>
      </Modal.Body>
</Modal></>)
}
export default Profile;