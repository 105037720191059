const InquirelySection = ({data}) => {
    return (
        <div id="inquirely_sec">
            <div className="container">
                <div className="row">
                    <h1>{"Why you’ll love Inquirely.com"}</h1>
                      {data && data.length > 0 && data.map((item) => {
                        return (<div className="col-lg-3 col-sm-6">
                        <div className="inquirely_box">
                            <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${item.image}`} alt="" />
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    </div>)
                      })}
                    

                    {/* <div className="col-lg-3 col-sm-6">
                        <div className="inquirely_box">
                            <img src="assets/images/s2.png" alt="" />
                            <h3>Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Morbi risus suspendisse dui pulvinar.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="inquirely_box">
                            <img src="assets/images/s3.png" alt="" />
                            <h3>Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Morbi risus suspendisse dui pulvinar.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="inquirely_box">
                            <img src="assets/images/s4.png" alt="" />
                            <h3>Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet consectetur. Morbi risus suspendisse dui pulvinar.</p>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>)
}
export default InquirelySection;