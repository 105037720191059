const DescriptionSection = ({data}) => {
    return (
        <section id="get_expert">
            <div class="container">
                {data && data.description ?
                    <div
                        dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                    : ""}
            </div>
        </section>
    )
}
export default DescriptionSection