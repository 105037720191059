export const headerData=[
    {
        "id":"1",
        "name":"Buy a Car",
        "segment":"BuyCar"
    },
    {
        "id":"2",
        "name":"Rent a Car",
        "segment":"RentCar"
    },
    {
        "id":"3",
        "name":"Sell Your Car",
        "segment":"SellYourCar"
    },
    {
        "id":"4",
        "name":"Rent Your Car",
        "segment":"RentYourCar"
    },
    {
        "id":"5",
        "name":"Check Your Car",
        "segment":"CheckYourCst"
    },
    {
        "id":"6",
        "name":"Electric cars",
        "segment":"ElectricCars"
    },
    {
        "id":"7",
        "name":"Classic cars",
        "segment":"classic_cars"
    }
];
export const footerData=[
    {
        "id":"1",
        "name":"Quick links",
        "sub_list":[{
            "id":"1",
            "name":"Home",
            "segment":""
        },
        {
            "id":"2",
            "name":"About Us",
            "segment":"about"
        },
        {
            "id":"3",
            "name":"For Experts",
            "segment":"experts"
        },
        {
            "id":"4",
            "name":"For Partners",
            "segment":""
        },
        {
            "id":"5",
            "name":"Testimonials",
            "segment":""
        },
        {
            "id":"6",
            "name":"Help",
            "segment":""
        }
    ]
    },
    {
        "id":"2",
        "name":"Other",
        "sub_list":[{
            "id":"1",
            "name":"Lorem",
            "segment":""
        },
        {
            "id":"1",
            "name":"Lorem ipsum",
            "segment":""
        },
        {
            "id":"1",
            "name":"Lorem ipsum",
            "segment":""
        },
        {
            "id":"1",
            "name":"Lorem ipsum",
            "segment":""
        },
        {
            "id":"1",
            "name":"Lorem ipsum",
            "segment":""
        },
    ]
    },
   
];

export const TostOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
export const social=[
    {
        "id":"1",
        "name":"Facebook",
        "icon":"facebook.png",
        "link":"#"
    },
    {
        "id":"2",
        "name":"Twitter",
        "icon":"linkdin.png",
        "link":"#"
    },
    {
        "id":"3",
        "name":"Linkedin",
        "icon":"twitter.png",
        "link":"#"
    },
];

export const checkbox=[
    {
        "id":1,
        "name":"ABS",
        "value":"abc"
    },
    {
        "id":2,
        "name":"Electric seats",
        "value":"electric_seats"
    },
    {
        "id":3,
        "name":"Power door locks",
        "value":"power_door_locks"
    },
    {
        "id":4,
        "name":"Air condition seats",
        "value":"air_condition_seats"
    },
    {
        "id":45,
        "name":"Airbags",
        "value":"airbags"
    },
    {
        "id":5,
        "name":"Head screen dashboard projector",
        "value":"head_screen_dashboard_projector"
    },
    {
        "id":6,
        "name":"Rear view camera",
        "value":"rear_view_camera"
    },
    {
        "id":345,
        "name":"Smart Entry",
        "value":"smart_entry"
    },
    {
        "id":7,
        "name":"Air condition",
        "value":"air_condition"
    },
    {
        "id":64,
        "name":"Cruise control",
        "value":"cruise_control"
    },
    {
        "id":8,
        "name":"Heated seats",
        "value":"heated_seats"
    },
    {
        "id":9,
        "name":"Sunroof",
        "value":"sunroof"
    },
    {
        "id":10,
        "name":"AUX/USB Plug",
        "value":"plug"
    },
    {
        "id":11,
        "name":"Heated steering wheel",
        "value":"heated_steering_wheel"
    },
    {
        "id":3,
        "name":"Smart parcking",
        "value":"smart_parcking"
    },
    {
        "id":4,
        "name":"Bluetooth",
        "value":"bluetooth"
    },
    {
        "id":5,
        "name":"Keyless entry",
        "value":"keyless_entry"
    },
    {
        "id":6,
        "name":"Touchscreen",
        "value":"touchscreen"
    },
    {
        "id":435,
        "name":"USB type C",
        "value":"usb_type_c"
    },
    {
        "id":7,
        "name":"Cooling box fridge",
        "value":"cooling_box_fridge"
    },
    {
        "id":8,
        "name":"Leather seats",
        "value":"leather_seats"
    },
    {
        "id":43,
        "name":"Led lights",
        "value":"led_lights"
    },
    {
        "id":9,
        "name":"Wireless charging",
        "value":"wireless_charging"
    },
    {
        "id":4,
        "name":"Electric Doors",
        "value":"electric_doors"
    },
    {
        
        "id":43,
        "name":"Electric mirrors",
        "value":"electric_mirrors"
    },
    {
        "id":5,
        "name":"Message seats",
        "value":"message_seats"
    },
    {
        "id":8,
        "name":"Navigation system",
        "value":"navigation_system"
    },
    {
        "id":6,
        "name":"Xenon lights",
        "value":"xenon_lights"
    },
    {
        "id":9,
        "name":"Parking sensors",
        "value":"parking_sensors"
    }
]

export const homebanner={
    cities:[],
    distance:[],
    make:[],
    model:[],
    min_price:[],
    max_price:[]
}
export const brandSection={
    topfour:[],
    all:[]
}

export const errorSms={
    first:"Something went wrong"
}

export const phoneRegExp="/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";

export const homeOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 5000,
    margin: 0,
    responsiveClass: true, 
    responsive: {
      0: { items: 1.5, nav: false },
      576: { items: 2, nav: true },
      768: { items: 2, nav: true },
      992: { items: 3, nav: true },
      1200: { items: 3, nav: true }
    }
  };

  export const homeSelect = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 5000,
    margin: 0,
    responsiveClass: true, 
    responsive: {
        0: { items: 2.5, nav: false },
        576: { items: 2.5, nav: true }
    }
  };

export function isFileSizeValid(files, maxSize) {
    let valid = true
    if (files) {
        files.map(file => {
            const fileSize = file.size
            if (fileSize > maxSize) {
                valid = false
            }
        })
    }
    return valid
}

export function isFileTypesValid(files, authorizedExtensions) {
    let valid = true
    if (files) {
        files.map(file => {
            console.log('file-type',file);
            console.log('file-authorizedExtensions',authorizedExtensions);
            if (!authorizedExtensions.includes(file.type)) {
                valid = false
            }
        })
    }
    return valid
}

export const testimonialContent = [
        {
            "name": "Cameron Williamson",
            "image": "assets/images/test1.png",
            "short_description": "Lorem ipsum dolor sit amet",
            "title": "Lorem ipsum dolor sit",
            "class": "active",
            "description": "Lorem ipsum dolor sit amet consectetur. Urna amet sapien vestibulum mauris ut. Porttitor lorem et auctor vitae viverra placerat fringilla. A nam neque sed feugiat scelerisque aliquet. Arcu fringilla consectetur odio tellus neque. Gravida sapien ullamcorper turpis duis venenatis lorem donec ultricies. Integer neque nam volutpat pretium metus. Enim sed viverra mollis vitae. Ullamcorper quisque est arcu aenean sagittis aliquam ligula ac eget. Egestas egestas eu ut mi non euismod neque in pellentesque. Sed eu quisque interdum enim elit etiam. Nunc ultricies pretium pulvinar eget dolor sed pellentesque ut. Mattis gravida tellus purus auctor pretium mattis arcu vitae.Malesuada orci cras semper viverra. Tellus adipiscing in morbi vulputate metus. Odio id nibh interdum sed mattis enim turpis egestas. Ullamcorper quam porttitor diam vulputate."
        },
        {
            "name": "Robert Fox",
            "image": "assets/images/test1.png",
            "short_description": "Lorem ipsum dolor sit amet",
            "title": "Lorem ipsum dolor sit",
            "class": "",
            "description": "Lorem ipsum dolor sit amet consectetur. Urna amet sapien vestibulum mauris ut. Porttitor lorem et auctor vitae viverra placerat fringilla. A nam neque sed feugiat scelerisque aliquet. Arcu fringilla consectetur odio tellus neque. Gravida sapien ullamcorper turpis duis venenatis lorem donec ultricies. Integer neque nam volutpat pretium metus. Enim sed viverra mollis vitae. Ullamcorper quisque est arcu aenean sagittis aliquam ligula ac eget. Egestas egestas eu ut mi non euismod neque in pellentesque. Sed eu quisque interdum enim elit etiam. Nunc ultricies pretium pulvinar eget dolor sed pellentesque ut. Mattis gravida tellus purus auctor pretium mattis arcu vitae.Malesuada orci cras semper viverra. Tellus adipiscing in morbi vulputate metus. Odio id nibh interdum sed mattis enim turpis egestas. Ullamcorper quam porttitor diam vulputate."
        },
        {
            "name": "Jerome Bell",
            "image": "assets/images/test1.png",
            "short_description": "Lorem ipsum dolor sit amet",
            "title": "Lorem ipsum dolor sit",
            "class": "",
            "description": "Lorem ipsum dolor sit amet consectetur. Urna amet sapien vestibulum mauris ut. Porttitor lorem et auctor vitae viverra placerat fringilla. A nam neque sed feugiat scelerisque aliquet. Arcu fringilla consectetur odio tellus neque. Gravida sapien ullamcorper turpis duis venenatis lorem donec ultricies. Integer neque nam volutpat pretium metus. Enim sed viverra mollis vitae. Ullamcorper quisque est arcu aenean sagittis aliquam ligula ac eget. Egestas egestas eu ut mi non euismod neque in pellentesque. Sed eu quisque interdum enim elit etiam. Nunc ultricies pretium pulvinar eget dolor sed pellentesque ut. Mattis gravida tellus purus auctor pretium mattis arcu vitae.Malesuada orci cras semper viverra. Tellus adipiscing in morbi vulputate metus. Odio id nibh interdum sed mattis enim turpis egestas. Ullamcorper quam porttitor diam vulputate."
        },
        {
            "name": "Manjeet Bell",
            "image": "assets/images/test1.png",
            "short_description": "Lorem ipsum dolor sit amet",
            "title": "Lorem ipsum dolor sit",
            "class": "",
            "description": "Lorem ipsum dolor sit amet consectetur. Urna amet sapien vestibulum mauris ut. Porttitor lorem et auctor vitae viverra placerat fringilla. A nam neque sed feugiat scelerisque aliquet. Arcu fringilla consectetur odio tellus neque. Gravida sapien ullamcorper turpis duis venenatis lorem donec ultricies. Integer neque nam volutpat pretium metus. Enim sed viverra mollis vitae. Ullamcorper quisque est arcu aenean sagittis aliquam ligula ac eget. Egestas egestas eu ut mi non euismod neque in pellentesque. Sed eu quisque interdum enim elit etiam. Nunc ultricies pretium pulvinar eget dolor sed pellentesque ut. Mattis gravida tellus purus auctor pretium mattis arcu vitae.Malesuada orci cras semper viverra. Tellus adipiscing in morbi vulputate metus. Odio id nibh interdum sed mattis enim turpis egestas. Ullamcorper quam porttitor diam vulputate."
        }
]

export const expertContent = [
    {
        "name": "kapil",
        "username": "kapil@ud.com",
        "Tier": "Basic Tier",
        "sub_category": "null",
        "short_description":"Lorem ipsum dolor sit",
        "user_image" : "assets/images/kapil.png",
        "category": "null",
        "email": "kapil@cccinfotech.com",
        "description": "Lorem ipsum dolor sit amet consectetur. Sodales pellentesque molestie in in tortor vestibulum morbi risus. Parturient sed feugiat nibh ornare tortor. Lectus lobortis amet massa blandit."
      },
      {
        "name": "Expert 1",
        "username": "neerajsingh@cccinfotech.com.live",
        "Tier": "null",
        "sub_category": "null",
        "short_description":"Lorem ipsum dolor sit",
        "user_image" : "assets/images/Expert1.png",
        "category": "null",
        "email": "neerajsingh@cccinfotech.com",
        "description": "Lorem ipsum dolor sit amet consectetur. Sodales pellentesque molestie in in tortor vestibulum morbi risus. Parturient sed feugiat nibh ornare tortor. Lectus lobortis amet massa blandit."
      },
      {
        "name": "Expert 2",
        "username": "neerajsingh@cccinfotech.com.live2",
        "Tier": "null",
        "sub_category": "null",
        "user_image": "assets/images/Expert2.png",
        "short_description":"Lorem ipsum dolor sit",
        "category": "null",
        "email": "neerajsingh@cccinfotech.com",
        "description": "Lorem ipsum dolor sit amet consectetur. Sodales pellentesque molestie in in tortor vestibulum morbi risus. Parturient sed feugiat nibh ornare tortor. Lectus lobortis amet massa blandit."
      },
      {
        "name": "Expert 3",
        "username": "expert3@cccinfotech.com.live3",
        "Tier": "null",
        "sub_category": "null",
        "short_description":"Lorem ipsum dolor sit",
        "user_image" : "assets/images/Expert3.png",
        "category": "null",
        "email": "kapil@cccinfotech.com",
        "description": "Lorem ipsum dolor sit amet consectetur. Sodales pellentesque molestie in in tortor vestibulum morbi risus. Parturient sed feugiat nibh ornare tortor. Lectus lobortis amet massa blandit."
      },
      {
        "name": "Expert 4",
        "username": "expert@cccinfotech.com.live4",
        "Tier": "null",
        "sub_category": "null",
        "short_description":"Lorem ipsum dolor sit",
        "user_image" : "assets/images/Expert4.png",
        "category": "null",
        "email": "kapil@cccinfotech.com",
        "description": "Lorem ipsum dolor sit amet consectetur. Sodales pellentesque molestie in in tortor vestibulum morbi risus. Parturient sed feugiat nibh ornare tortor. Lectus lobortis amet massa blandit."
      }
];

export const Leader = [
    {
      image: "assets/images/m1.png",
      name: "Jhon Willem",
      specialist: "CEO"   
    },
    {
      image: "assets/images/m2.png",
      name: "Lorem Ipusm",
      specialist: "VP of Engineering"   
    },
    {
      image: "assets/images/m3.png",
      name: "Lorem Ipusm",
      specialist: "Chief of Staff"   
    },
    {
      image: "assets/images/m4.png",
      name: "Lorem Ipusm",
      specialist: "VP of Finance"   
    },
    {
      image: "assets/images/m1.png",
      name: "Jhon Willem",
      specialist: "CEO"   
    },
    {
      image: "assets/images/m2.png",
      name: "Lorem Ipusm",
      specialist: "VP of Engineering"   
    }
]

export function convertBase64 (file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }