import React, { useEffect,useState } from 'react';
import { NavLink,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Tools/Button';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { errorSms } from '../../../data/content';
import { clearStatus, loginForm } from '../../../Reducer/authSlice';
import FormInput from '../../Tools/FormInput';


export default function Login({remove}) {
const dispatch = useDispatch();
const navigate = useNavigate();
const submitting = useSelector(state => state.auth.submitting);
const login = useSelector(state => state.auth.auth);
const error = useSelector(state => state.auth.error);
const success = useSelector(state => state.auth.success);
const statedata = useSelector(state => state);
console.log("statedata", statedata);
const schema = Yup.object().shape({
    email: Yup.string().required().email('Please Enter Valid email address'),
     password:Yup.string().min(6,'Password must be at least 6 characters').max(30,'Password must be at most 30 characters').required('Password is a required field'),
  
});

const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

const onError = (errors) => {
    console.log("error", errors);
};

const onSubmit = async(data) => {
    if(submitting==false)
    {
        let formData=new FormData();
        formData.append('email',data.email);
        formData.append('password',data.password);
        dispatch(loginForm(formData));
    }
};

if(error && error.status)
{
  switch (error.status) {
    case 403:
        toast.error(error.data.message);
        dispatch(clearStatus());
        remove();
      break;
    default:
        toast.error(errorSms.first);
        dispatch(clearStatus());
        remove();
      break;
  }
}
if(success)
{
    toast.success('User login successfully');
    remove();
}


return (<>
    <form onSubmit={handleSubmit(onSubmit, onError)}>
    <div class="tab-pane fade show active" id="With_Phone" role="tabpanel" aria-labelledby="With_Phone-tab">
        <div class="form-group">
            <label>Email</label>
            <FormInput style={'form-control'} intype={'email'}  event={register} name={'email'} placeholder={'Please Enter the Email Address'}/>
            {errors.email ? <p>{'Email is a required field'}</p>:errors.phone && <p>{'Phone number is not valid'}</p>}
        </div>
        <div class="form-group">
            <label>{'Password'}</label>
            <span class="password d-block">
            <FormInput style={'form-control'} intype={'password'} event={register} name={'password'} placeholder={'Please Enter The Password'}/>
            <i class="far fa-eye"></i>
            </span>
            <NavLink to={'/Login'} class="forgot mt-1">{'Forgot Password?'}</NavLink>
            {errors.password && <p>{errors.password.message}</p>}
        </div>
        <Button style="btn" title={submitting==false ? 'Login': 'Please wait...'} type="submit"/>
    </div>
    </form>
    </>);
}