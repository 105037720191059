import { AiFillStar } from 'react-icons/ai';
import React from 'react';
import axios from 'axios';
import { testimonialApi } from '../../../services/api';
import ListItem from '../../Tools/ListItem';
const TestimonialComponent = ({ heading }) => {
    const [content, setContent] = React.useState([]);
    React.useEffect(()=>{
      getAllTestimonial();
    }, [])
   const getAllTestimonial = async () => {
    try 
    {
        const response = await testimonialApi();
        if(response.data.status)
        {
            setContent(response.data.data);
        }
    }
    catch(e) { console.log("error", e); }
   }
   content.map((item, i)=>{
     if(i==0)
     {
        item.class = "active";
     }
     else
     {
        item.class = "";
     }
   })
   console.log("content", content);
    return (<div className="container"><div id="testimonials">
            <h1>{heading}<label> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"><i className="bi bi-chevron-left"></i></span>
                <span className="visually-hidden">Previous</span>
            </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"><i className="bi bi-chevron-right"></i></span>
                    <span className="visually-hidden">Next</span>
                </button> </label>
            </h1>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="row">
                    <div className="col-md-4">
                        <div className="carousel-indicators">
                            {content.map((item, i) => {
                                return <div type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={`slider_btn ${item.class}`} >
                                    <div className="slider_img">
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${item.image}`} alt="" />
                                    </div>
                                    <div className="slider_heading">
                                        <h5>{item.username}</h5>
                                        <p>{item.review_title}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="carousel-inner">
                            {content.map((item, i) => {
                                return <div className={`carousel-item ${item.class}`}>
                                    <div className="carousel-caption">
                                        <h5>{item.title}</h5>
                                        <span><AiFillStar className='star' /><AiFillStar className='star' /><AiFillStar className='star' /><AiFillStar className='star' /><AiFillStar className='star' /></span>
                                        <p>{item.review_description}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default TestimonialComponent;