import { checkVideoExixt } from "../../../services/helper";
const HowItsWorkExpert = ({data}) => {
    return (<section id="work_sec">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    {data && data.howitswork_content ?
                        <div
                            dangerouslySetInnerHTML={{ __html: data.howitswork_content }}
                        />
                        : "Loading..."}
                </div>
                <div className="col-lg-6">
                    <div className="work_img">
                        {checkVideoExixt(data && data.howitswork_image ? data.howitswork_image : "") ? <video loop autoplay="" muted>
                            <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data.howitswork_image}`} type="video/mp4" />
                        </video> : <img src={data && data.howitswork_image ? `https://adminaccess.inquirely.com/inquirely//public/uploads/${data.howitswork_image}` : `images/how-work.png`} alt="" />}
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default HowItsWorkExpert;