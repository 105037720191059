import React from "react";
import { useParams } from "react-router-dom";
import BannerSection from "./bannerSection";
import { getblogsByIdApi } from "../../../services/api";
const Spotlight = () => {
    const [data, setData] = React.useState([]);
    const {id} = useParams();
   React.useEffect(() => {
    getBlogsByIdFun(id);
   }, [setData])
   const getBlogsByIdFun = async (id) => {
    const body = {
        id: id
    }
    try {
    const response = await getblogsByIdApi(body);
    if(response.data.status) {
        setData(response.data.data);
    }
    }
    catch (e) {
        console.log("error",e)
    }
   }
   console.log("data", data);
  return(<>
    <BannerSection data={data} />
    <section class="single_page">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data && data.sideImage}`} alt="single_img"/>
            </div>
            <div class="col-sm-6">
            <div
                        dangerouslySetInnerHTML={{ __html: data && data.description ? data.description : "" }}
                    />
            </div>
        </div>
    </div>
    </section>
  </>)
}
export default Spotlight;