import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, Login, Header, Footer, Signup, BasicDetail } from "./Components";
import RouteWebPage from "./routes";
import AuthRoute from "./AuthRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./Components/Tools/Loader";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Loader />
        <Header />
        <RouteWebPage />
        {/* <Route exact path="/Details" element={<AuthRoute element={<BasicDetail />} />}/> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
