import React from "react";
import { NavLink } from "react-router-dom";
import ModalButton from "../ModalButton";
import Button from "../Button";
const ListItem = ({value,style,segment,onClick,innerhtmlType,innerData,buttonStyle,listClick,buttonTitle,btnType,buttonId}) => {
    var html="";
    if(innerhtmlType=="link")
    {
        html=(<NavLink className="nav-link" to={`/${segment}`}>{value}</NavLink>);
    }
    if(innerhtmlType=="modalButton")
    {
        html=(<ModalButton type={innerData.type} styleClass={innerData.class} toggle={innerData.toggle} selected={innerData
        .selected} target={innerData.target} controls={innerData.controls} role={innerData.role} title={innerData.title}/>);
    }
    if(innerhtmlType=="button")
    {
        html=(<Button type={btnType} btnId={buttonId} onClick={onClick} title={buttonTitle} style={buttonStyle}/>);
    }
    if(innerhtmlType=="html")
    {
        html=value;
    }
    
    return (
        <li className={style} onClick={listClick} >
            {html}
        </li>
    )
}
export default ListItem;