import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BannerSection from "./BannerSection";
import AboutSection from "./about";
import { home } from "../../../services/api";
import CategoryCrousel from "./categoryCrousel";
import InquirelySection from "./inquirelySection";
import TestimonialComponent from "../../Layouts/crousel/testimonialCrousel";
import ExpertsSection from "../../Layouts/crousel/expertsection";
import { testimonialContent } from "../../../data/content";
import { expertContent } from "../../../data/content";
import { setHomeImage } from '../../../Reducer/categorySlice';
import Loader from "../../Tools/Loader";
// import HowItsWorkSlick from "./howItsWorkSlick";
// import ScrollFix from "./scrollfix";
import VerticalCarousel from "./VerticalCarousel";
import { changeStatus } from "../../../Reducer/loaderSlice";

export default function Home() {
    const [homedata, setHomeData] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStatus(true));
        getHome();
    }, [setHomeData]);
    const getHome = () => {
        dispatch(setHomeImage())
        try {
            home().then((response) => {
                if (response.data.status) {
                    setHomeData(response.data.data);
                    dispatch(changeStatus(false));
                }
            }).catch((e) => {
                console.log('error', e)
            })
        }
        catch (e) {
            console.log("error", e);
        }
    }
    const howItsWorkTab = [
        homedata?.howitswork_tabtitle_1,
        homedata?.howitswork_tabtitle_2,
        homedata?.howitswork_tabtitle_3,
        homedata?.howitswork_tabtitle_4
    ];

    const items = [
        {
            howItsTitle: homedata?.howitswork_title_1,
            howitsContent: homedata?.howitswork_content
        },
        {
            howItsTitle: homedata?.howitswork_title_2,
            howitsContent: homedata?.howitswork_content_2
        },
        {
            howItsTitle: homedata?.howitswork_title_3,
            howitsContent: homedata?.howitswork_content_3
        },
        {
            howItsTitle: homedata?.howitswork_title_4,
            howitsContent: homedata?.howitswork_content_4
        }
    ];

    const loveInquirelySection = [
        {
            image: homedata?.loveInquirelyImage1,
            title: homedata?.loveInquirelyTitle1,
            description: homedata?.loveInquirelyDescription1
        },
        {
            image: homedata?.loveInquirelyImage2,
            title: homedata?.loveInquirelyTitle2,
            description: homedata?.loveInquirelyDescription2
        },
        {
            image: homedata?.loveInquirelyImage3,
            title: homedata?.loveInquirelyTitle3,
            description: homedata?.loveInquirelyDescription3
        },
        {
            image: homedata?.loveInquirelyImage4,
            title: homedata?.loveInquirelyTitle4,
            description: homedata?.loveInquirelyDescription4
        }
    ]
    return (<>
        <div>
            {/* {!isLoading ? <Loader /> : ""} */}
            <section id="sec1">
                <BannerSection homedata={homedata} />
                <CategoryCrousel />
                <AboutSection homedata={homedata} />
            </section>
            {/* <HowItsWorkSlick homedata={homedata}  /> */}
            <VerticalCarousel items={items} tab={howItsWorkTab} />
            <section id="sec2">
                <InquirelySection data={loveInquirelySection} />
                <TestimonialComponent heading={"Testimonial"} />
            </section>
            <ExpertsSection content={expertContent} heading={"Experts"} />
        </div>
    </>);
}