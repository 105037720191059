export const spacingExixtReplaceUnderscore = (string) => {
    let result;
    const spliting = string.split(' ');
    if(spliting.length > 1)
     {
       return string.replace(/ /g, "_");
     }
    else
     {
        result = string;
        return result;
     }
}

export const checkVideoExixt = (image) => {
  return image?.includes('mp4') ? true : false
}