// import axios from 'axios';
import LoginResponce from '../data/loginresponce.json';
import RegisterResponce from '../data/register.json';

const API_BASE_URL = process.env.APIURL; // Replace with your API base URL

  export const submitLoginForm = async (formData) =>{
    try {
      const response = LoginResponce;
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const registerFormSubmit = async (formData)=>{
    try {
      //const response = await axios.post(`${API_BASE_URL}/registerUser/`);
      const response = RegisterResponce;
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const detailFormSubmit = async(formData)=>{
    try {
      //const response = await axios.post(`${API_BASE_URL}/registerUser/`);
      const response = {"status":"success"};
      return response;
    } catch (error) {
      throw error;
    }
  }
