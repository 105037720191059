import React, { useEffect,useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { socket } from '../../../socket';
import swal from 'sweetalert';
import { paymentAdd,profile_details } from '../../../services/api';



export default function Checkout({clientId}) {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [fooEvents, setFooEvents] = useState([]);
  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   function onFooEvent(value) {
  //     setFooEvents(previous => [...previous, value]);
  //   }

  //   socket.on('connection', onConnect);
  //   socket.on('disconnect', onDisconnect);
  //   socket.on('foo', onFooEvent);

  //   return () => {
  //     socket.off('connection', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     socket.off('foo', onFooEvent);
  //   };
  // }, []);

    const stripe = useStripe();
    const elements = useElements();

    // useEffect(()=>{
    //   get_profile();
    // },[])
    const payMoney = async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setPaymentLoading(true);
      const clientSecret = clientId;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Faruq Yusuff",
            address: {
              line1: '510 Townsend St',
              postal_code: '98140',
              city: 'San Francisco',
              state: 'CA',
              country: 'US',
            }
          },
        },
      });
      console.log('paymentResult',paymentResult);
      setPaymentLoading(false);
      if (paymentResult.error) {
        swal("Oops!", paymentResult.error.message, "error");
        // alert(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          console.log('paymentResult',paymentResult);
          swal("Complted!",' Payment Successfully Done', "success");
           let ds={uid:18,email:'kunalb.digittrix@gmail.com',id:paymentResult.paymentIntent.id,amount:paymentResult.paymentIntent.amount,time:paymentResult.paymentIntent.created,}
          let add= await paymentAdd(ds);
          // if(add.status==200)
          // {
          //   calculateAmount(add.data.result);
          // }
          
        }
      }
    };

    // const calculateAmount=(data)=>{
    //   console.log('data',data);
    //   let i=wallet;
    //   for await (const num of data) {
    //     i+=num.amount;
    //   }
    //   setWallet(i);
    // }

    // const get_profile = async()=>{
    //   let ds={uid:18};
    //   let add= await profile_details(ds);
    //   if(add.status==200)
    //   {
    //     calculateAmount(add.data.result);
    //   }
    // }

  
    return (
      <div
        style={{
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}
            onSubmit = {payMoney}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardElement
                className="card"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white"
                    } 
                  },
                }}
              />
              <button
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Pay"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }