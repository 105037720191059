import React, { useEffect,useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CurrencyInput from 'react-currency-input-field';
import "./index.css";
import Checkout from "./checkout.js";
import Button from '../Button';
import { makeClientId } from '../../../services/api';
export default function Payment({remove}){
  const stripe = loadStripe(
    "pk_test_51NVfYaSFS0SUbPMOXScMrn5JQv1lpHYry982Irji9F5ANLxhwizHr50MZCr1ZvNFjy8NTxhu8n7RSPr7pLI52AqS00ND6yQ6jT"
  );
  const [amount, setAmount] = useState(100);
  const [clientId, setClientId] = useState('');

  const addAmount=async()=>{
    if(amount>0)
    {
      let body={'amount':amount,'currency':'usd'};
      let responce = await makeClientId(body);
      if(responce.status==200)
      {
        setClientId(responce.data.clientId);
      }
    }
  }

  return (
    <div className='form-group'>
      <CurrencyInput
      id="input-example"
      name="input-name"
      placeholder="Please enter amount"
      defaultValue={amount}
      decimalsLimit={2}
      onValueChange={(value, name) => setAmount(value)}
      />
      <Button onClick={addAmount} title={'Add'} />
      <Elements stripe={stripe}>
        {clientId!=='' ?
        <Checkout clientId={clientId} remove={remove}/>:""
        }
      </Elements>
      </div>
  );
};
