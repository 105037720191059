import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '../../Tools/ListItem';
import { headerData } from '../../../data/content';
import { changeLanguage, clearToken, clearUser } from '../../../Reducer/authSlice';
import Modal from 'react-bootstrap/Modal';
import Button from '../../Tools/Button';
// import '../../../css/custom.css';
import ImageBox from '../../Tools/ImageBox';

import Login from './login';

export default function Header() {
  const dynamicLogo = useSelector(state=>state.singleCategory.image);
  const token = useSelector(state => state.auth.token);
  const login = useSelector(state => state.auth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
 
  const datalist = headerData;
  const staticImage = 'https://adminaccess.inquirely.com/inquirely//public/uploads/header-logo.png';
  const handlelogout = () => {
    dispatch(clearUser());
    dispatch(clearToken());
    navigate('/');
  }
  const sethandleShow = ()=>{
    setShow(true);
  }

  const handleClose = () => setShow(false);
  return (<header className="header">
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/"><ImageBox style={'w-100'} dynamicImage={staticImage} path={dynamicLogo} alt=""  /></Link>
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="w-100">
            <ul className="navbar-nav float-end">
              <ListItem style={'nav-item'} innerhtmlType={'link'} segment={''} value={'Home'}  />
              <ListItem style={'nav-item'} innerhtmlType={'link'} segment={'about'} value={'About Us'}  />
              <ListItem style={'nav-item'} innerhtmlType={'link'} segment={'experts'} value={'For Experts'}  />
              <ListItem style={'nav-item'} innerhtmlType={'link'} segment={''} value={'For Partners'}  />
              <ListItem style={'nav-item'} innerhtmlType={'link'} segment={''} value={'Help'}  />
            </ul>
          </div>
        </div>
        <div className="responsive-login">
          <ul className="d-flex align-items-center">
            {token && token!==null ?
            <>
            <li className="nav-item dropdown"><a onClick={handlelogout} className="btn">{'Logout'}</a></li>
            <ListItem style={'btn'} innerhtmlType={'link'} segment={'profile'} value={'My Profile'}  />
            </>
            :
            <li className="nav-item dropdown"><a onClick={sethandleShow} className="btn">{'Login'}</a></li>
            }
            
          </ul>
          <Button style={'navbar-toggler'} type={'button'} title={<span className="navbar-toggler-icon"><i className="bi bi-list"></i></span>}></Button>
        </div>
      </div>
    </nav>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Login remove={handleClose}/>
      </Modal.Body>
    </Modal>
  </header>
  );
}
