import OwlCarousel from 'react-owl-carousel3';
const ExpertsSection = ({ content, heading }) => {
    const state = {
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    }

    return (
        <>
         <div class="container">
            <section id="experts">
                    <h1>{heading}</h1>
                    <div class="home-demo">
                        <OwlCarousel className='owl-theme' loop margin={10} navText={['<', '>']} nav responsive={state.responsive}>
                            {content ? content.map((item, i) => {
                                {console.log("item", item)}
                                return <div class='item'>
                                    <div class="experts_img">
                                        <img src={item.user_image} alt="" />
                                        <div class="experts_heading">
                                            <h5>{item.name} <span> <img src={item.user_image} alt="" /> 541 </span></h5>
                                            <p>{item.email}</p>
                                        </div>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            }) : ""}
                        </OwlCarousel>
                    </div>
            </section>
            </div>
        </>
    )
}
export default ExpertsSection;