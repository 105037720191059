const FaqSection = () => {
    return (<section id="faq_sec">
        <div className="container">
            <h1>FAQs</h1>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <span>Q</span> Lorem ipsum dolor sit amet consectetur. A ultrices id mi amet. Diam cursus amet tellus leo orci sed.
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Lorem ipsum dolor sit amet consectetur. Neque fermentum a quisque pharetra aenean adipiscing non praesent scelerisque. Massa risus lorem integer et feugiat nunc tortor porttitor diam. Ut eget nunc lacus nulla eu nisi viverra. Sed in semper a pulvinar nunc scelerisque curabitur. Magna quis porttitor lacus orci scelerisque vel volutpat vel et. Quam eros diam auctor lorem gravida platea suscipit a. Tellus nec dui adipiscing odio id quam. Felis cras suspendisse sit ornare. Aliquam urna morbi egestas donec ut id facilisi. Aenean gravida nam consequat scelerisque fermentum convallis orci.
                            Mauris platea libero consequat scelerisque orci. Sed sed id sed in blandit risus tristique. Nunc felis neque volutpat ultrices aenean. Urna consectetur gravida eget leo interdum purus ornare posuere. Tortor rhoncus viverra nisi sed in. Mauris cras vel ac consequat. Enim felis a eget nulla pulvinar eu auctor convallis. Fringilla vitae consectetur urna sed faucibus fringilla. Felis non diam erat vel varius pulvinar elementum aliquet
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span>Q</span> Lorem ipsum dolor sit amet consectetur. A ultrices id mi amet. Diam cursus amet tellus leo orci sed.
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <span>Q</span>Lorem ipsum dolor sit amet consectetur. A ultrices id mi amet. Diam cursus amet tellus leo orci sed.
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                            <span>Q</span> Lorem ipsum dolor sit amet consectetur. A ultrices id mi amet. Diam cursus amet tellus leo orci sed.
                        </button>
                    </h2>
                    <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}
export default FaqSection;