
import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';

const Loader = () => {
    const loader = useSelector(state => state.loader.loader);
     console.log('l-loader',loader);
    return loader === true && <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
}

export default Loader;