import axios from "axios";
const nodeSever='http://172.105.60.241:5900/api/v1';

export const allCategory = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/allcategory');
}

export const home = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/home');
}

export const expertsData = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/experts',{mode:'cors'});
}

export const specificCategoryByName = async (name) => {
   return await axios.post('https://adminaccess.inquirely.com/inquirely/api/specific_category', { name });
}

export const loginApi = async (body) => {
   return await axios.post('https://adminaccess.inquirely.com/inquirely/api/user_login', body);
}

export const profileApi = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/profile');
}

export const expertApi = async () => {
   return await axios.get('https://uniteddavidautoparts.my.salesforce-sites.com/services/apexrest/UserList');
}

export const testimonialApi = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/get_all_testimonials');
}

export const makeClientId = async (body) =>{
   try{
      return await axios.post(nodeSever+'/payment-init',body);
   }catch(e){
      return e;
   }
}

export const paymentAdd = async (body) =>{
   try{
      return await axios.post('https://adminaccess.inquirely.com/inquirely/api/payment_comp',body);
   }catch(e){
      return e;
}
}
export const profile_details = async (body) =>{
   try{
      return await axios.post('https://adminaccess.inquirely.com/inquirely/api/profile_details',body);
   }catch(e){
      return e;
}
}

export const getBenefitApi = async () => {
   return await axios.get('https://adminaccess.inquirely.com/inquirely/api/get_all_benefit');
}

export const getAllCustomPages = async () => {
   try {
    return await axios.get('https://adminaccess.inquirely.com/inquirely/api/get_all_custompages');
   }catch(e) {
      return e;
   }
}

export const getAllBlogs = async () => {
   try {
      return await axios.get('https://adminaccess.inquirely.com/inquirely/api/allblogs');
   }
   catch(e) {
      return e;
   }
}

export const getblogsByIdApi = async (body) => {
   try {
      return await axios.post('https://adminaccess.inquirely.com/inquirely/api/getblogsbyid', body);
   }
   catch(e) {
      return e;
   }
}

export const getByIdCustomPages = async (body) => {
   try {
    return await axios.post('https://adminaccess.inquirely.com/inquirely/api/get_by_id_custompages', body)
   }catch(e) {
      return e;
   }
}

export const getByIdCategoryTestimonialApi = async (body) => {
   try {
     return await axios.post('https://adminaccess.inquirely.com/inquirely/api/getByIdCategorytestimonial', body);
   }catch (e) {
      return e
   }
}