import React, { useEffect } from 'react';
import ListItem from '../../Tools/ListItem';
import { footerData } from '../../../data/content';
import { getAllCustomPages } from '../../../services/api';
import { spacingExixtReplaceUnderscore } from '../../../services/helper';
export default function Pages({ style }) {
    const [customPages, setCustomPages] = React.useState([]);
    const footer = footerData;
    useEffect(() => {
        getAllCustomPagesFun()
    }, [])
    const getAllCustomPagesFun = async () => {
        try {
            const response = await getAllCustomPages();
            if (response.data.status) {
                setCustomPages(response.data.data);
            }
        } catch (e) {
            console.log("error", e);
        }
    }
    console.log("custom pages", customPages);
    return (<>
        {footer.length > 0 ? footer.map((item) => {
            return (
                <div className={style}>
                    <h5>{item.name}</h5>
                    <ul>
                        {item.sub_list.map((item, key) => {
                            return (
                                <ListItem key={key} value={item.name} segment={item.segment} innerhtmlType={'link'} />)
                        })}
                        {customPages.length > 0 && customPages.map((itemm, key) => {
                            if (itemm.position == item.id) {
                                return <ListItem key={key} value={itemm.page_name} segment={spacingExixtReplaceUnderscore(itemm.page_name)} innerhtmlType={'link'} />
                            }
                        })}
                    </ul>
                </div>)
        }) : ""}
    </>
    );
}