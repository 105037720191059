import React, { useEffect, useState } from 'react';

import { checkVideoExixt } from '../../../services/helper';
import SalesforceChatbot from '../../chatbot/chatbot';
export default function BannerSection({ homedata }) {
    return (<div id="home_banner" style={{ backgroundImage: `url("https://adminaccess.inquirely.com/inquirely//public/uploads/${homedata ? homedata.banner_image : "home-banner.png"}")` }}>
        {checkVideoExixt(homedata && homedata.banner_image) ? <video loop autoplay="" muted>
            <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${homedata.banner_image}`} type="video/mp4" />
        </video> : ""}
        <div className="container">
            <div className="test">
                <SalesforceChatbot name={'more'} />
            </div>
            <div className="row">
                <div className="col-md-8 order_number">
                    <div className="home_heading">
                        <h1>{homedata && homedata.banner_title ? homedata.banner_title : ""}</h1>
                        <h3>{homedata && homedata.banner_description ? homedata.banner_description : ""}</h3>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="home_banner_img">
                        {!checkVideoExixt(homedata && homedata.banner_image) ? <img src="images/home-banner-img.png" alt="" /> : ""}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
