import { checkVideoExixt } from "../../../services/helper";
const ApplicationProcessExpert = ({data}) => {
    return(<section id="application_process">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                {checkVideoExixt(data && data.aplication_process_image ? data.aplication_process_image : "") ? <video loop autoplay="" muted>
                    <source src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data.aplication_process_image}`} type="video/mp4" />
                </video> : <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${data.aplication_process_image}`} />}
            </div>
        </div>
    </div>
</section>)
}
export default ApplicationProcessExpert;