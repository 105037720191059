import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const BoostrapModal = ({ show, onHide, heading, data }) => {
    console.log("data", data);
    return (
        <Modal show={show} size="lg" animation={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="bio__modal">
                    <p class="bio__who"><b class="bio__name">Jhon Willem</b>, CEO</p><span class="bio__photo"><img class="bio__photo-image" src="assets/images/m1.png" /></span>
                    <p class="bio__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa doloremque eos atque ratione velit possimus reiciendis voluptatum,
                        maiores nihil debitis placeat explicabo veritatis cumque soluta recusandae ipsa cum dolor! Officiis? lore Lorem ipsum dolor sit amet consectetur.
                        Ultrices quis augue tempus lectus iaculis. Tincidunt id iaculis interdum penatibus. Justo nisi sagittis est egestas quis in in. Tortor vel ut risus
                        nullam blandit adipiscing nisl.
                        Posuere pellentesque faucibus sem consectetur at eget consectetur.
                    </p>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default BoostrapModal;