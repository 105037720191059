

import React from "react";
const ImageBox = ({value, style, path, dynamicImage }) => {
    return (
        <div className={style}>
            <div class="set">
                <img src={path ? `https://adminaccess.inquirely.com/inquirely//public/uploads/${path}` : dynamicImage} alt="img"/>
                <h6>{value}</h6>
            </div>
        </div>
    )
}
export default ImageBox;
