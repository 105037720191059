import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Components/Pages/Home';
import AboutUs from '../Components/Pages/About';
import { allCategory, getAllCustomPages } from '../services/api';
import { spacingExixtReplaceUnderscore } from '../services/helper';
import CategoryPage from '../Components/Pages/singlePage'
import ExpertPage from '../Components/Pages/Experts';
import Test from '../test';
import LoginForm from '../Components/Pages/Login';
import Profile from '../Components/Pages/profile';
import AuthRoute from '../AuthRoute';
import CustomPages from '../Components/Pages/Custompages';
import Spotlight from '../Components/Pages/singleSpotlight';

const RouteWebPage = () => {
    const [data, setData] = React.useState(false);
    const [customPages, setCustomPages] = React.useState(false);
    React.useEffect(() => {
        allCategoryRouteCreate();
        getCustomPages();
    }, [])

    const getCustomPages = () => {
        getAllCustomPages().then((response)=>{
            if(response.data.status) {
                setCustomPages(response.data.data);
            }
        }).catch((e)=>{
            console.log("error", e)
                })
    }
    const allCategoryRouteCreate = () => {
        allCategory().then((response) => {
            if (response.data.status) {
                setData(response.data.data);
            }
        }).catch((e) => {
            console.log("error", e);
        })
    }
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<AboutUs />} />
            {data ? data.map((item, index) => {
                return <Route path={`/${spacingExixtReplaceUnderscore(item.name)}`} element={<CategoryPage />}></Route>
            }) : ""}
            {customPages.length > 0 && customPages.map((item)=>{
                 return <Route path={`/${spacingExixtReplaceUnderscore(item.page_name)}`} element={<CustomPages />}></Route>
                                                                                                                       
            })}
            <Route exact path='/spotlight/:id' element={<Spotlight />} />
            <Route exact path='/experts' element={<ExpertPage />} />
            <Route exact path='/test' element={<Test />} />
            <Route exact path='/Login' element={<LoginForm />} />
            {/* <Route exact path='/profile' element={<AuthRoute element={<Profile />} />} /> */}
            <Route exact path='/profile' element={<Profile/>} />
        </Routes>
    )
}

export default RouteWebPage;