import { NavLink, Navigate, useLocation } from 'react-router-dom';
import Pages from './Pages';
import Button from '../../Tools/Button';
import CategoryLinks from './categoryLinks';
export default function Footer() {
    return (<>
        <div className='mobile_foot'>
            <div className='container'>
                <ul>
                    <li> <a class="active" href='#'><img src="assets/images/dash_img/b-1.png" /></a> </li>

                    <li> <a href='#'><img src="assets/images/dash_img/b-2.png" /></a> </li>

                    <li> <a href='#'><img src="assets/images/dash_img/b-3.png" /></a> </li>

                    <li> <a href='#'><img src="assets/images/dash_img/b-4.png" /></a> </li>
                </ul>
            </div>
        </div>
        <footer id="footer">
            <div class="container">
                <div class="row">
                    <Pages style={'col-lg-2 col-md-3 col-6 mt-3'} />
                    <CategoryLinks />
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <h5>Contact Us</h5>
                        <ul class="social">
                            <li> <a href="#"> <i class="bi bi-telephone"></i> {'000000000000'} </a></li>
                            <li> <a> <i class="bi bi-geo-alt"></i> {'2715 Ash Dr. San Jose, South Dakota 83475'} </a></li>

                        </ul>
                        <h6>Follow us</h6>
                        <ul class="social_icon">
                            <li> <a href=""> <img src="assets/images/facebook.png" alt="" /> </a> </li>
                            <li> <a href=""> <img src="assets/images/linkdin.png" alt="" /> </a> </li>
                            <li> <a href=""> <img src="assets/images/twitter.png" alt="" /> </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.2/TweenMax.min.js"></script>
            <script src="https://cdn.rawgit.com/Promo/wheel-indicator/f047fdff/lib/wheel-indicator.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/plugins/ScrollToPlugin.min.js"></script>
        </footer>
    </>
    );
}