import React from "react"
import { getBenefitApi } from "../../../services/api";

const InquirelySection = () => {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        getAllBenefit();
    }, []);
    const getAllBenefit = async () => {
        try {
            setIsLoading(false);
            const response = await getBenefitApi();
            if (response.data.status) {
                setData(response.data.data);
                setIsLoading(true);
            }
        }
        catch (e) {
            setIsLoading(true);
            console.log("error", e);
        }
    }
    console.log("data", data);
    return (<>
    <section id="verified_experts">
        <div class="container">
            <div class="row">
                {data && data.map((e, i) => {
                    if (e.description.length > 300) {
                        return (<div class="col-lg-8 col-md-12">
                            <div class="verified_box">
                                <h3>{e.title}</h3>
                                <p>{e.short_description}</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="verified_img">
                                            <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${e.image}`} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="verified_box_para">
                                            <p>{e.description.substr(0, 200)}</p>
                                            <p>{e.description.substr(0, 200)}</p>
                                            <p>{e.description.substr(0, 200)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                    else {
                        return (
                            <div class="col-lg-4 col-md-12">
                                <div class="verified_box">
                                    <h3>{e.title}</h3>
                                    <p>{e.short_description}</p>
                                    <div class="verified_img">
                                        <img src={`https://adminaccess.inquirely.com/inquirely//public/uploads/${e.image}`} alt="" />
                                    </div>
                                    <p>{e.description}</p>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    </section></>)
}

export default InquirelySection


