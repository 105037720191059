import React from "react";
import { allCategory } from "../../../services/api";
import { spacingExixtReplaceUnderscore } from "../../../services/helper";
import ListItem from "../../Tools/ListItem";
const CategoryLinks = () => {
    const [category, setCategory] = React.useState([]);
    React.useEffect(() => {
        getCategory();
    }, [])
    const getCategory = async () => {
        allCategory().then((response) => {
            if (response.data.message) {
                setCategory(response.data.data);
            }
        })
    }
    return (<div class="col-lg-5 col-md-6 col-sm-12 mt-3">
        <h5>{"Categories"}</h5>
        <div class="row">
            <div class="col-12">
                <ul>
                    {category.map((e, i) => {
                        return <ListItem segment={spacingExixtReplaceUnderscore(e.name)} value={e.name} innerhtmlType={'link'} />
                    })}
                </ul>
            </div>
        </div>
    </div>)
}
export default CategoryLinks;