import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { specificCategoryByName } from '../services/api';

export const singleCategoryData = createAsyncThunk('/home',async (formData, { rejectWithValue }) => {
  try {
    const response = await specificCategoryByName(formData);
    console.log("response reducer", response);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const categorySlice = createSlice({
  name: 'singleCategory',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    home:{},
    image:""
  },
  reducers: {
    setHomeImage: (state, action) => {
      state.image = "header-logo.png";
    }
  },
  extraReducers: builder => {
    builder
      .addCase(singleCategoryData.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(singleCategoryData.fulfilled, (state,action) => {
        state.submitting = false;
        state.home = action.payload;
        state.image=action.payload.categoryImage;
        state.success=true;
        state.error = null;
      })
      .addCase(singleCategoryData.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});

export const { setHomeImage } = categorySlice.actions;
export default categorySlice.reducer;