import React from 'react';
import ReactDOM from 'react-dom/client'; // Import 'react-dom/client' for createRoot
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { changeLanguage, setToken, setUser } from './Reducer/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TostOptions } from './data/content';
import { changeStatus } from './Reducer/loaderSlice';

const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : "";
const token = localStorage.getItem('token') ? localStorage.getItem('token') : "";

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}
store.dispatch(changeStatus(true));

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root')); // Create root

root.render(
  <Provider store={store}>
    <ToastContainer {...TostOptions} />
    <App />
  </Provider>
);
