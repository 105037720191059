import { createSlice } from '@reduxjs/toolkit';

const LoaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loader: true,
  },
  reducers: {
    changeStatus: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const { changeStatus } = LoaderSlice.actions;
export default LoaderSlice.reducer;
