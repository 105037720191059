const FormSection = ({data}) => {
   return ( <section id="for_experts" style={{ backgroundImage: `url("https://adminaccess.inquirely.com/inquirely//public/uploads/${data ? data.banner : "./images/experts-bg.png"}")` }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-4">
                <div className="experts_img_sec">
                    <img src={"assets/images/expert-img.png"} />
                </div>
            </div>
            <div className="col-lg-6 col-md-8">
                <div className="experts_form">
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                        <input type="hidden" name="oid" value="00D5g00000FB04s" />
                        <input type="hidden" name="retURL" value="https://inquirely.net/" />
                        <h5>Experts, apply here!</h5>
                        <div className="form-group">
                            <label for="salutation" className="form-label">Salutation</label>
                            <select className="form-select" aria-label="Default select example" name="salutation" id="salutation">
                                <option value="">--None--</option>
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Dr.">Dr.</option>
                                <option value="Prof.">Prof.</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="first_name" className="form-label">First Name</label>
                                    <input id="first_name" maxLength="40" name="first_name" size="20" type="text" className="form-control" placeholder="First Name" />

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="last_name" className="form-label">Last Name</label>
                                    <input id="last_name" maxLength="80" name="last_name" size="20" type="text" className="form-control" placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="email" className="form-label">Email</label>
                                    <input id="email" maxLength="80" name="email" size="20" type="text" className="form-control" placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="mobile" className="form-label">Molile</label>
                                    <input id="mobile" maxLength="40" name="mobile" size="20" type="text" className="form-control" placeholder="Mobile" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="industry" className="form-group">Industry</label><select id="industry" className="form-control" name="industry"><option value="">--None--</option><option value="Agriculture">Agriculture</option>
                                        <option value="Apparel">Apparel</option>
                                        <option value="Banking">Banking</option>
                                        <option value="Biotechnology">Biotechnology</option>
                                        <option value="Chemicals">Chemicals</option>
                                        <option value="Communications">Communications</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Consulting">Consulting</option>
                                        <option value="Education">Education</option>
                                        <option value="Electronics">Electronics</option>
                                        <option value="Energy">Energy</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Environmental">Environmental</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                                        <option value="Government">Government</option>
                                        <option value="Healthcare">Healthcare</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Machinery">Machinery</option>
                                        <option value="Manufacturing">Manufacturing</option>
                                        <option value="Media">Media</option>
                                        <option value="Not For Profit">Not For Profit</option>
                                        <option value="Other">Other</option>
                                        <option value="Recreation">Recreation</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Technology">Technology</option>
                                        <option value="Telecommunications">Telecommunications</option>
                                        <option value="Transportation">Transportation</option>
                                        <option value="Utilities">Utilities</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="phone" className="form-label">Phone</label>
                                    <input id="phone" maxLength="40" name="phone" size="20" type="text" className="form-control" placeholder="Phone" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="Website" className="form-label">Website</label>
                                    <input id="url" maxLength="80" name="url" size="20" type="text" className="form-control" placeholder="Website" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="street" className="form-label">Street</label>
                                    <textarea name="street" className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="city" className="form-label">City</label>
                                    <input id="city" maxLength="40" name="city" size="20" type="text" className="form-control" placeholder="City" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="phone" className="form-label">State/Province</label>
                                    <input id="state" maxLength="20" name="state" size="20" type="text" className="form-control" placeholder="State/Province" />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="country" className="form-label">Country</label>
                                    <input id="country" maxLength="40" name="country" size="20" type="text" className="form-control" placeholder="Country" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label for="zip" className="form-label">Zip</label>
                                    <input id="zip" maxLength="20" name="zip" size="20" type="text" className="form-control" placeholder="Zip" />
                                </div>
                            </div>
                        </div>
                        <button type="submit" name="submit">Next</button>

                    </form>
                </div>
            </div>

        </div>
    </div>
</section>)
}
export default FormSection;