const Content = ({content}) => {
    return (<section>
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div dangerouslySetInnerHTML={{ __html: content && content }} />
                </div>
            </div>
        </div>
    </section>)
}
export default Content;