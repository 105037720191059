import React from 'react';
import Loader from '../../Tools/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { singleCategoryData } from '../../../Reducer/categorySlice';
import { specificCategoryByName } from '../../../services/api';
import { testimonialContent } from '../../../data/content';
import ExpertsSection from '../../Layouts/crousel/expertsection';
import { expertContent } from '../../../data/content';
import BannerSection from './bannerSection';
import DescriptionSection from './descriptionSection';
import InquirelySection from './inquirelySection';
import TestimonialComponent from '../../Layouts/crousel/testimonialCrousel';
import { changeStatus } from '../../../Reducer/loaderSlice';
import { getAllBlogs, getByIdCategoryTestimonialApi } from '../../../services/api';

const Categorypage = () => {
    const [data, setData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [spotlight, setSpotlight] = React.useState([]);
    const [categoryTestimonial, setCategoryTestimonial] = React.useState([]);
    const dispatch = useDispatch();
    let test = window.location.href.split('/');
    let test2 = test[test.length - 1].replace(/_/g, ' ');
    React.useEffect(() => {
        let urlParams = window.location.href.split('/');
        let categoryName = urlParams[urlParams.length - 1].replace(/_/g, ' ');
        specifiCategory(categoryName);
        getAllSpotlight();
    }, [window.location.href])
    const specifiCategory = (name) => {
        setIsLoading(false);
        dispatch(singleCategoryData({ name }))
        try {
            specificCategoryByName(name).then(async (response) => {
                if (response.data.status) {
                    setData(response.data.data);
                    dispatch(changeStatus(false));
                    const id = response.data.data.id;
                    const result = await getByIdCategoryTestimonialApi({ id });
                    console.log("result", result)
                    if (result.data.status) {
                        setCategoryTestimonial(result.data.data)
                    }
                }
            }).catch((e) => {
                console.log("error", e)
            });
        }
        catch (e) {
            console.log("error", e);
        }
    }
    let value;
    switch (test2) {
        case "legal": value = 'fourth';
            break;
        case "Medical and health": value = "second";
            break;
        case "Technology": value = "first";
            break;
        case "Home Improvement": value = "third";
            break;
        case "Finance and Tax": value = "fivth";
            break;
        case "Travel and Tourism": value = "sixth";
            break;
        case "Education and tutoring": value = 'seventh';
            break;
        case "Fashion and Style": value = "eigth";
            break;
        case "Automotive": value = "ninth";
            break;
        case "Nutrition and Dietetics": value = "tenth";
            break;
        case "Travel and Tourism": value = "eleven";
            break;
        case "Sports and Fitness": value = "twelve";
            break;
        case "Parenting and Childcare": value = "thirteen";
            break;
        case "Yoga": value = "fourteen";
            break;
        case "More": value = 'more';
            break;
    }

    const getAllSpotlight = async () => {
        try {
            const response = await getAllBlogs();
            if (response.data.status) {
                setSpotlight(response.data.data)
            }
            console.log("response", response);
        }
        catch (e) {
            console.log("error", e);
        }
    }

    console.log("categoryTestimonial", categoryTestimonial);
    return (
        <>
            {/* {!isLoading ? <Loader /> : ""} */}
            <BannerSection data={data} value={value} spotlight={spotlight} categoryTestimonial={categoryTestimonial && categoryTestimonial} />
            <DescriptionSection data={data} />
            <TestimonialComponent content={testimonialContent} heading={"Testimonials"} />
            <ExpertsSection content={expertContent} heading={"expert"} />
            <InquirelySection />
        </>
    )
}

export default Categorypage;