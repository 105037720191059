const AboutSection = ({ homedata }) => {
    return (<><div className='testing the chatBot'>
    </div>
    <div id="about_us" style={{ backgroundImage: `url("/images/about-bg.png")` }}>
        <div className="container">
            <div
                dangerouslySetInnerHTML={{ __html: homedata && homedata.about_content ? homedata.about_content : "" }}
            />
        </div>
    </div></>)
}
export default AboutSection;